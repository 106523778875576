import React from "react";
import Section from "components/Section";
import SectionHeader from "components/SectionHeader";
import Newsletter from "components/Newsletter";
import OtherBrowser from "./OtherBrowser";
import CTAButton from "./CTAButton";

function CTAbottomSection(props) {
  return (
    <section
      className="SectionComponent hero section is-block is-relative py-8"
      style={{
        backgroundImage: "linear-gradient(225deg, #ADEBD1 0%, #AECFE1 100%)",
        overflow: "hidden",
      }}
      id="addtobrowser"
    >
      <div className="container has-text-centered pt7" style={{ paddingBottom: "6rem" }}>
        <div
          className="is-vcentered is-centered is-variable is-8 is-multiline py-3"
          style={{ maxWidth: "514px", marginLeft: "auto", marginRight: "auto" }}
        >
          <div className="has-text-centered">
            <h3 style={{ fontSize: "24px", fontWeight: "700" }}>Select your brower:</h3>
            <div className="buttons is-inline-flex">
              <OtherBrowser all={true} type={"blueBg"} />
            </div>
            <h3 style={{ fontSize: "24px", fontWeight: "700", marginTop: "12px" }}>
              Or try our mobile apps:
            </h3>
            <div className="buttons is-inline-flex">
              <OtherBrowser mobileAppsOnly={true} type={"blueBg"} />
            </div>
            <div
              className="CTAsubtitle"
              style={{
                fontWeight: "700",
                letterSpacing: "0.54px",
                color: "#060C0E",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                marginTop: "0.8rem",
                zIndex: "100",
              }}
            >
              • NO SIGN UP
              <span style={{ marginRight: "4px" }} /> • ALL FEATURES
              <span style={{ marginRight: "4px" }} /> • UNLIMITED TIME
            </div>
            {/* <OtherBrowser type={"blueBg"} /> */}
          </div>
        </div>
      </div>
      <div style={{ position: "absolute", top: "60%", left: "70%" }}>
        <div
          className="preFooterBgBox1"
          style={{
            //y: box1y,
            //rotate: -10,
            transform: "rotate(-10deg)",
            position: "absolute",
            background: "rgba(223,255,251,0.50)",
            border: "2px solid rgba(255,255,255,0.50)",
            boxShadow: "inset -2px -2px 25px 0 rgba(255,255,255,0.80)",
            borderRadius: "20px",
            backdropFilter: "blur(6px)",
            zIndex: "6",
          }}
        />
        <div
          className="preFooterBgBox2"
          style={{
            position: "absolute",
            transform: "rotate(-10deg)",
            background: "rgba(223,255,251,0.50)",
            border: "2px solid rgba(255,255,255,0.50)",
            boxShadow: "inset -2px -2px 25px 0 rgba(255,255,255,0.80)",
            borderRadius: "20px",
            zIndex: "5",
          }}
        />
        {/* <div
          className="preFooterBgBox3"
          style={{
            //y: box3y,
            //rotate: -10,
            position: "absolute",
            transform: "rotate(-10deg)",
            background: "rgba(223,255,251,0.50)",
            border: "2px solid rgba(255,255,255,0.50)",
            boxShadow: "inset -2px -2px 25px 0 rgba(255,255,255,0.80)",
            borderRadius: "20px",
            backdropFilter: "blur(6px)",
            zIndex: "4",
            opacity: "0.9",
          }}
        />
        <div
          className="preFooterBgBox4"
          style={{
            //y: box4y,
            //rotate: -10,
            position: "absolute",
            transform: "rotate(-10deg)",
            backgroundImage: " linear-gradient(134deg, #016AEA 0%, #BA0086 100%)",
            borderRadius: "20px",
            //boxShadow: "20px 142px 104px 0 rgba(0,0,0,0.20)",
            zIndex: "3",
          }}
        /> */}
      </div>
    </section>
  );
}

export default CTAbottomSection;
